import React from 'react';
import { Flex, Spinner, Text } from "@chakra-ui/react";

const FullPageLoader = () => {
  return (
    <Flex 
      position="fixed" 
      top={0} 
      left={0} 
      right={0} 
      bottom={0} 
      backgroundColor="rgba(255, 255, 255, 0.8)" 
      alignItems="center" 
      justifyContent="center"
      zIndex="1000"
    >
      <Spinner size="lg" color="teal.500" />
    </Flex>
  );
};

export default FullPageLoader;
