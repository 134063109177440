import React from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea
} from '@chakra-ui/react'
import { Controller } from 'react-hook-form'

const ETextarea = ({ label, name, placeholder, control, disabled, readonly }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          <FormLabel>{label}</FormLabel>
          <Textarea {...field} placeholder={placeholder} minHeight={'158px'} disabled={disabled} isReadOnly={readonly} fontSize={'14px'}/>
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}

export default ETextarea
