import { Button, Flex, Text } from "@chakra-ui/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "components/Card/Card";
import SearchEmployeeForm from "components/SearchEmployee/SearchEmployeeForm";
import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

import {
  FIELD_SEARCH_EMPLOYEE_CODE,
  FIELD_SEARCH_EMPLOYEE_NAME,
  FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
  FIELD_SEARCH_EMPLOYEE_TYPE,
  FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
  FIELD_SEARCH_EMPLOYEE_ROLE_1,
  FIELD_SEARCH_EMPLOYEE_ROLE_2,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_2,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_3,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_4,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_5,
} from "constants";
import { customSearchEmployeeInput } from "utils";
import { FIELD_SEARCH_EMPLOYEE_JOB } from "constants";
import SearchEmployeeResult from "components/SearchEmployee/SearchEmployeeResult";
import useSearchEmployee from "hooks/useSearchEmployee";
import { useMasterData } from "hooks/useMasterData";
import CardHeader from "components/Card/CardHeader";

const FORM_KEYS = [
  "name",
  "from",
  "to",
  "work_status",
  "role_1",
  "job_code",
  "role_2",
  "employment_type_code",
  "code",
  "department_2_code",
  "department_3_code",
  "department_4_code",
  "department_5_code",
  "employee_group",
  "target_group_id",
];

const COL = 3;

const IndividualTrainingAssign = () => {
  const schema = yup.object().shape({
    from: yup.string().nullable(),
    to: yup
      .string()
      .nullable()
      .test("error", t("messages.date_end"), (value, context) => {
        return !(value && new Date(value) < new Date(context.parent.from));
      }),
  });
  const history = useHistory();

  const { data: masterData } = useMasterData();
  const {
    paramsListEmployee,
    onSubmit,
    setParamsListEmployee,
    pagination,
    setPagination,
    onChangeSelectAll,
    dataEmployees,
    isLoading,
    checkAllCondition,
    checkSelected,
    handleCheckboxChange,
    setSelectedListState,
    selectedListState,
    setSelectedList,
  } = useSearchEmployee();

  const isShowEmployeeResult = useRef(false);
  const employeeGroup = useRef(null);

  const methods = useForm({
    defaultValues: FORM_KEYS.reduce((obj, key) => {
      if (key === "work_status") {
        obj[key] = "1"; // Set default work_status value to 1
      } else {
        obj[key] = null;
      }
      return obj;
    }, {}),
    resolver: yupResolver(schema),
  });

  const handleResetListCourse = () => {
    setSelectedListState([]);
  };

  const handleSearchEmployeeDefault = () => {
    setSelectedListState([]);
    refetchListCourse();
    onSubmit({clearSearch: true});
  };

  const submitAndClose = () => {
    // clear data selection search
    FORM_KEYS.map((formKey) => {
      // not clear selection employee_group
      if (formKey === 'employee_group') return;

      methods.setValue(formKey, "");
    });

    onSubmit({});
    setSelectedList(selectedListState);
  };

  const handleSearchEmployee = (value) => {
    isShowEmployeeResult.current = true;
    onSubmit(value);
  };

  useEffect(() => {
    // if change group type is check all
    if (dataEmployees && dataEmployees.length > 0 && isShowEmployeeResult.current && employeeGroup.current) {
      onChangeSelectAll(true, true);
      employeeGroup.current = null;
    }
  }, [dataEmployees]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 20px 0px">
          <Text fontSize="xl" fontWeight="bold">
            {t("pages.individual.assign.title")}
          </Text>
        </CardHeader>
        <SearchEmployeeForm
          methods={methods}
          inputList={[
            FIELD_SEARCH_EMPLOYEE_CODE,
            FIELD_SEARCH_EMPLOYEE_NAME,
            FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_TYPE, {
              data: [{ id: "", name: "" }].concat(
                masterData?.employment_types ?? []
              ),
            }),
            FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
            FIELD_SEARCH_EMPLOYEE_ROLE_1,
            FIELD_SEARCH_EMPLOYEE_ROLE_2,
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_JOB, {
              label: "pages.course_form.job_type",
              data: [{ id: "", name: "" }].concat(masterData?.jobs ?? []),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_2, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_2 ?? []
              ),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_3, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_3 ?? []
              ),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_4, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_4 ?? []
              ),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_5, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_5 ?? []
              ),
            }),
          ]}
          col={COL}
          submitAndClose={submitAndClose}
          handleSubmit={methods.handleSubmit(handleSearchEmployee)}
          enableTargetGroup
          setSelectedListState={setSelectedListState}
          selectedData={selectedListState}
          formKeys={FORM_KEYS}
          handleResetListCourse={handleResetListCourse}
          handleSearchEmployeeDefault={handleSearchEmployeeDefault}
          onChangeSelectAll={onChangeSelectAll}
          onChangeGroup={(value) => employeeGroup.current = value}
        />
        <SearchEmployeeResult
          isLoading={isLoading}
          dataEmployees={isShowEmployeeResult.current ? dataEmployees : []}
          paramsListEmployee={paramsListEmployee}
          setParamsListEmployee={setParamsListEmployee}
          pagination={pagination}
          setPagination={setPagination}
          totalCount={dataEmployees?.result?.meta?.total}
          checkSelected={checkSelected}
          handleCheckboxChange={handleCheckboxChange}
          onChangeSelectAll={onChangeSelectAll}
          checkAllCondition={checkAllCondition}
          maxHeigh="200px"
        />
        <Flex w="100%" flex="1" textAlign="right" pr="6%" pl="4%" justifyContent="center">
          <Button
            onClick={() => history.push('individual-training/list')}
            color="white"
            background="#007bff"
            fontSize="14px"
            _hover={{ background: "#007bff99" }}
            size={"sm"}
            px={4}
          >
            {t("pages.individual.btn_go_to_list")}
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default IndividualTrainingAssign;
