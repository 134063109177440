import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react'
import React from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import { Controller } from 'react-hook-form'
import styles from './style.module.scss'


const EDateRangePicker = ({
  disabled,
  dateFormat = 'yyyy-MM-dd',
  name,
  getValues,
  reset,
  placeholder,
  control,
  style
}) => {
  const onChange = (dates) => {
    reset({ ...getValues(), [name]: dates })
  }
  registerLocale('ja', ja)

  return (
    <Controller
      style={{width:"100%"}}
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          <ReactDatePicker
            onChange={onChange}
            selected={getValues(name)}
            disabled={disabled}
            customInput={<Input fontSize={'14px'} sx={style} />}
            placeholderText={placeholder}
            locale='ja'
            wrapperClassName={styles.searchEmployeeDatePicker}
            dateFormat={dateFormat}
          />
          <FormErrorMessage fontSize={'14px'}>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}

export default EDateRangePicker
