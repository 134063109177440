import {
  Flex,
  Text,
  HStack,
  Button,
  Box,
  Spinner
} from '@chakra-ui/react'
import { getDetailCourse } from 'hooks/useCourse'
import { usePushJobManual } from 'hooks/useCourse'
import { t } from 'i18next'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import GreenButton from 'components/Buttons/GreenButton'
import DisableButton from 'components/Buttons/DisableButton'
import { toast } from 'react-toastify'
import { COURSE_TYPE_ASSIGN_RANDOM } from 'constants'
// Chakra imports

const CourseDetailIndividual = () => {
  const [detailCourse, setDetailCourse] = useState()
  const history = useHistory()

  const [isRequiredAll, setIsRequiredAll] = useState()
  const { courseId } = useParams()
  const { data, isError, isLoading, refetch } = getDetailCourse(courseId, true)
  const mutationPushJobManual = usePushJobManual()

  if (isError) {
    history.push('/admin/not-found')
  }

  const onPushManual = (id) => {
    if (detailCourse?.campus_status !== 3) {
      if (detailCourse?.document?.name) {
        mutationPushJobManual.mutate({ id: id })
        return
      }
    }
    toast.error(t('pages.courses.detail.error_course_name'))
  }

  const handleClick = (event) => {
    event.currentTarget.disabled = true;
  };

  const employeeList = (courseData) => {
    if (!courseData) {
      return
    }
    const uList = courseData.user_selected_list ? JSON.parse(courseData.user_selected_list).map((target) => target.name).join(', ') : ''
    if (courseData.is_required_all) {
      if (uList) {
        return `${t('pages.course_form.all_except')} \n${uList}`
      } else {
        return `${t('pages.course_form.all_people')}`
      }
    } else {
      if (uList) {
        return `${uList}`
      } else {
        return ``
      }
    }
  }

  useEffect(() => {
    if (data?.result?.data) {
      setIsRequiredAll(!!data?.result?.data?.is_required_all)
      setDetailCourse(data?.result?.data)
    }
  }, [data?.result?.data])

  useEffect(() => {
    // const interval = setInterval(async () => {
    //   if (detailCourse?.campus_status === 2) {
    //     await refetch()
    //   }
    // }, 30000)

  }, [detailCourse])

  return (
    <Flex direction='column' pt={{ base: '120px', md: '40px' }}>
      <Card overflowx={{ sm: 'hidden', xl: 'hidden' }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex justify="space-between">
            <Box>
              <Text fontSize="20px" fontWeight='bold'>
                {t('pages.courses.detail.title')}
              </Text>
            </Box>
          </Flex>
        </CardHeader>
        {isLoading ? (
          <Box textAlign='center' mt='40px'>
            <Spinner />
          </Box>
        ) : (
          <CardBody marginLeft='30px' display='flex'>
            <div style={{ width: '100%' }}>
              <HStack display='flex' marginTop='15px'>
                <Text
                  fontSize="14px"
                  width='160px'
                  marginRight='20px'
                  align='end'
                >
                  {t('pages.courses.detail.target_group')}
                </Text>
                <Text fontSize="14px">
                  {detailCourse?.type &&
                    COURSE_TYPE_ASSIGN_RANDOM[detailCourse?.type]}
                </Text>
              </HStack>
              <HStack display='flex' marginTop='15px'>
                <Text
                  fontSize="14px"
                  width='160px'
                  marginRight='20px'
                  align='end'
                >
                  {t('pages.courses.detail.course_material')}
                </Text>
                <Text fontSize="14px" width='500px' whiteSpace='pre-line'>
                  {detailCourse?.document?.course_code ? detailCourse?.document?.course_code : ''} {detailCourse?.document?.name ? detailCourse?.document?.name : ''}
                </Text>
              </HStack>

              <HStack display='flex' marginTop='15px'>
                <Text
                  fontSize="14px"
                  width='160px'
                  marginRight='20px'
                  align='end'
                >
                  {t('pages.courses.detail.target_audience_name')}
                </Text>
                <Text fontSize="14px" width='500px' whiteSpace='pre-line'>
                  {employeeList(detailCourse)}
                </Text>
              </HStack>
              <HStack
                direction='row'
                spacing={4}
                align='center'
                justifyContent='end'
                margin='10px'
                w="85%"
              >
                <Button
                  background='#00C209'
                  variant='solid'
                  width='70px'
                  onClick={() =>
                    history.push(`/admin/individual-training/courses/edit/${courseId}`)
                  }
                  size={'sm'}
                >
                  {t('pages.courses.detail.edit')}
                </Button>
                <Button
                  background='#BCBCBC'
                  variant='outline'
                  width='70px'
                  onClick={() => history.go(-1)}
                  size={'sm'}
                >
                  {t('pages.courses.detail.back')}
                </Button>
              </HStack>
              <Flex justify='center'>
                <GreenButton
                  mr='50px'
                  text={t('pages.job_assign_history.list.title')}
                  onClick={() => history.push(`/admin/job-history/${courseId}`)}
                />
                {
                  detailCourse?.campus_status == 2 ? (
                    <DisableButton
                      text={t('pages.course_form.pushing')}
                      isLoading={'true'}
                    />
                  ) : (
                    <GreenButton
                      text={t('pages.course_form.cloud_campus_status_1')}
                      onClick={(e) => {onPushManual(courseId);handleClick(e)}}
                    />
                  )
                }
              </Flex>
            </div>
          </CardBody>
        )}
      </Card>
    </Flex>
  )
}

export default CourseDetailIndividual
