import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next"
import { useGetListDocument } from "hooks/useDocument";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  useColorModeValue
} from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Pagination from "components/Pagination/Pagination";
import UploadFile from "components/UploadFile";
import { useForm } from "react-hook-form";
import { useImportCsv } from "hooks/useDocument";
import { CSV_EXTENSION } from "constants";
import GreenButton from "components/Buttons/GreenButton";
import ModalDeleteDocument from "../../components/modals/ModalDeleteDocument";
import {useModalState} from "../../hooks/useModalState";
import {useDeleteDocument} from "../../hooks/useDocument";
import DeleteButton from "../../components/Buttons/DeleteButton";
import { documentTypeName } from "utils";


const DocumentList = () => {
  const history = useHistory()
  const [searchName, setSearchName] = useState('')
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [params, setParams] = useState({
    page: 1,
    limit: 15,
    filter: '',
    sortType: -1,
    sort: 'id'
  })

  const { data, isLoading, error } = useGetListDocument(params)
  const tableData = data?.result?.data
  const total = data?.result?.meta?.total

  const mutationUploadCsv = useImportCsv()
  const mutationDeleteDocument = useDeleteDocument()

  const [currentId, setCurrentId] = useState()
  const [selectedDocumentCode, setSelectedDocumentCode] = useState()

  const methods = useForm({
    defaultValues: {
      file: null
    }
  })
  const { modalState, open, close, closeAll } = useModalState({
    modalDeleteDocument: false,
  })
  const onSubmitDelete = (id) => {
    mutationDeleteDocument.mutate(id)
    close('modalDeleteDocument')
  }

  const enterSearch = (e) => {
    if (e.key === 'Enter') {
      search()
    }
  }

  const search = () => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.filter = searchName
      tempData.page = 1
      return tempData
    })
  }

  const changeSort = (typeParams) => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.sortType = typeParams
      tempData.sort = 'name'
      return tempData
    })
  }

  const onSubmit = (data) => {
    const ext = data.file[0].name.split('.').pop().toLowerCase();
    if (ext !== CSV_EXTENSION) {
      methods.setError('file', {
        type: 'manual',
        message: t('pages.document.list.upload_csv.error')
      })
    } else {
      const formData = new FormData();
      formData.append("file", data.file[0]);
      mutationUploadCsv.mutate(formData)
    }
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 40px 0px">
          <Box justifyContent={'space-between'} >
            <Text fontSize='xl' fontWeight='bold'>
              {t('pages.document.list.title')}
            </Text>
          </Box>
          <Flex align="end">
            <Box w="50%">
              <FormControl>
                <Flex mt="20px">
                  <FormLabel w="150px" mt={{ base: "7px" }} fontSize="14px" textAlign={'end'} mr={'30px'} fontWeight={'bold'}>
                    {t("pages.document.list.search_form.search_label")}
                  </FormLabel>
                  <Input w="200px" value={searchName} onChange={(e) => setSearchName(e.target.value)} onKeyDown={enterSearch} fontSize="14px" />
                  <Button
                    onClick={search}
                    ml="20px"
                    background="#007bff"
                    color="white"
                    fontSize="14px"
                    w="80px"
                    mt={'5px'}
                    _hover={{ background: "#007bff99" }}
                    size={'sm'}
                  >
                    {t("pages.document.list.search_form.search_button")}
                  </Button>
                </Flex>
              </FormControl>
            </Box>
            <Spacer />
            <Box w="50%" flex="1" textAlign="right" pr="8.5%" >
              <Button
                onClick={() => history.push(`/admin/documents/create`)}
                color="white"
                background="#007bff"
                fontSize="14px"
                _hover={{ background: "#007bff99" }}
                size={'sm'}
              >
                {t('pages.document.list.create_new')}
              </Button>
            </Box>
          </Flex>
          <Box w={'100%'} justifyContent={'space-between'} maxWidth={'800px'}>
            <form onSubmit={methods.handleSubmit(onSubmit)} >
              <Flex>
                <Box w={'50%'} display={'flex'} mt={'20px'}>
                  <UploadFile name='file' control={methods.control} formMethods={methods} textBtn={t("pages.document.list.select_csv")} accept={'.csv'}/>
                </Box>
                <Box w={'50%'} display={'flex'} mt={'20px'}>
                  <GreenButton type='submit' text={t("pages.document.list.import")}/>
                </Box>
              </Flex>
            </form>
          </Box>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="simple" w="100%" size={'xs'}>
              <Thead>
                <Tr>
                  <Th borderColor={borderColor} color="black" width="50px">
                  </Th>
                  <Th borderColor={borderColor} color="black" w="8%" fontSize="14px">
                    {t("pages.document.type")}
                  </Th>
                  <Th borderColor={borderColor} color="black" w="15%" fontSize="14px">
                    {t("pages.document.list.table.target_group_name")}
                  </Th>
                  <Th borderColor={borderColor} color="black" w="15%" fontSize="14px">
                    {t("pages.document.list.table.course_id")}
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px">
                    {t("pages.document.list.table.document_name")}
                    {params.sortType === -1 ? (
                      <ChevronUpIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort(1)}
                      />
                    ) : (
                      <ChevronDownIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort(-1)}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor} color="black" w="40%" fontSize="14px">
                    {t("pages.document.list.table.document_detail")}
                  </Th>
                  <Th borderColor={borderColor} color="black" textAlign="center" fontSize="14px">
                    {t("pages.document.list.table.to_detail")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData && tableData.map((item, index) => (
                  <Tr key={index}>
                    <Td borderColor={borderColor} textAlign="center" fontSize="14px">
                      {index + 1 + (params.limit * (params.page - 1))}
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all" fontSize="14px">
                      {documentTypeName(item.type)}
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text noOfLines={1} fontSize="14px">
                        {item.targetGroup?.name ?? ''}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text noOfLines={1} fontSize="14px">
                        {item.course_code}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text noOfLines={1} fontSize="14px">
                        {item.name}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text noOfLines={1} fontSize="14px">
                        {item.description}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} fontSize="14px" textAlign={'right'} paddingRight={'10px'}>
                      {item.courses?.length < 1 && (
                          <DeleteButton
                              text={t("pages.document.list.table.delete_button")}
                              size={'xs'}
                              styles={{ fontSize: '14px' }}
                              marginRight={'15px'}
                              onClick={() => {
                                open('modalDeleteDocument')
                                setCurrentId(item.id)
                                setSelectedDocumentCode(item.course_code)
                              }}
                          />
                      )}
                      <Button
                          onClick={() => history.push(`/admin/documents/edit/${item.id}`)}
                          color="white"
                          background="#007bff"
                          fontSize="14px"
                          _hover={{ background: "#007bff99" }}
                          size={'xs'}
                      >
                        {t('pages.document.list.table.to_detail_button')}
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {Math.ceil(total / params.limit) > 1 && (
              <Pagination total={total} perPage={params.limit} currentPage={params.page} setParams={setParams} />
            )}
          </TableContainer>
          {isLoading && (
            <Box textAlign="center" mt="40px">
              <Spinner></Spinner>
            </Box>
          )}
          {
            tableData && tableData.length === 0 && (
              <Box textAlign="center" pt="20px" color="gray.400" fontSize="14px">
                {t('messages.common_s003')}
              </Box>
            )
          }
          {
            error && (
              <Box textAlign="center" pt="20px" color="gray.400" fontSize="14px">
                {t('messages.common_s005')}
              </Box>
            )
          }
        </CardBody>
      </Card>
      <ModalDeleteDocument
          isOpen={modalState.modalDeleteDocument}
          content={`コースID ${selectedDocumentCode} ${t("pages.document.list.table.confirm_delete")}`}
          onClose={() => close('modalDeleteDocument')}
          onSubmit={() => onSubmitDelete(currentId)}
      />
    </Flex>
  );
}

export default DocumentList;