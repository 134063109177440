import { useQuery } from "@tanstack/react-query";
import { get } from "api/requests";

const getMasterData = async () => {
  const res = await get("master-data");

  return res?.result?.data ?? [];
};

export const useMasterData = () => {
  return useQuery(["get-master-data"], () => getMasterData(), {
    enable: true,
  });
};
