import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import style from "./style.module.scss";

export default function ESelectWithSearch({
  name,
  control,
  options,
  placeholder,
  onChange,
  isDisabled,
  height = 38,
}) {
  const styles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#e2e8f0',
      minHeight: height,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: height - 2,
      padding: '0 6px'
    }),
    // indicatorSeparator: state => ({
    //   display: 'none',
    // }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: height - 2,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 4,
    }),
    option: (provided) => ({
      ...provided,
      minHeight: 32,
    }),
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          <Select
            {...field}
            options={options}
            menuPosition="fixed"
            placeholder={placeholder}
            className={style.font_14}
            onChange={onChange}
            styles={styles}
            isDisabled={isDisabled}
          />
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}
