import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { t } from "i18next";

// Components
import Card from "components/Card/Card";
import SearchEmployeeForm from "components/SearchEmployee/SearchEmployeeForm";
import SearchEmployeeResult from "components/SearchEmployee/SearchEmployeeResult";
import CardHeader from "components/Card/CardHeader";
import PrimaryButton from "components/Buttons/PrimaryButton";

// Hooks
import useSearchEmployee from "hooks/useSearchEmployee";
import { useMasterData } from "hooks/useMasterData";
import { getDetailCourse } from "hooks/useCourse";

// Constants and Utils
import {
  FIELD_SEARCH_EMPLOYEE_CODE,
  FIELD_SEARCH_EMPLOYEE_NAME,
  FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
  FIELD_SEARCH_EMPLOYEE_TYPE,
  FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
  FIELD_SEARCH_EMPLOYEE_ROLE_1,
  FIELD_SEARCH_EMPLOYEE_ROLE_2,
  FIELD_SEARCH_EMPLOYEE_JOB,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_2,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_3,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_4,
  FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_5,
} from "constants";
import { customSearchEmployeeInput } from "utils";

const FORM_KEYS = [
  "name",
  "from",
  "to",
  "work_status",
  "role_1",
  "job_code",
  "role_2",
  "employment_type_code",
  "code",
  "department_2_code",
  "department_3_code",
  "department_4_code",
  "department_5_code",
  "employee_group",
];

const IndividualTrainingAssignEmployee = () => {
  const location = useLocation();
  const history = useHistory()
  const courseId = location.state.courseId;

  const [listEmployee, setListEmployee] = useState([]);
  const isFirst = useRef(true);

  const schema = yup.object().shape({
    from: yup.string().nullable(),
    to: yup
      .string()
      .nullable()
      .test(
        "error",
        t("messages.date_end"),
        (value, context) =>
          !(value && new Date(value) < new Date(context.parent.from))
      ),
  });

  const { data: dataCourse } = courseId 
    ? getDetailCourse(courseId, true) 
    : { data: null };
  const { data: masterData } = useMasterData();

  const {
    paramsListEmployee,
    onSubmit,
    setParamsListEmployee,
    pagination,
    setPagination,
    onChangeSelectAll,
    dataEmployees,
    isLoading,
    checkAllCondition,
    checkSelected,
    handleCheckboxChange,
    setSelectedList,
    selectedListState,
  } = useSearchEmployee();

  const methods = useForm({
    defaultValues: FORM_KEYS.reduce(
      (acc, key) => ({ ...acc, [key]: null }),
      {}
    ),
    resolver: yupResolver(schema),
  });

  const submitAndClose = () => {
    // clear data selection search
    FORM_KEYS.map((formKey) => {
      methods.setValue(formKey, "");
    });

    onSubmit({});
    setSelectedList(selectedListState);
    if (courseId) {
      sessionStorage.setItem('useSelectedList', JSON.stringify(selectedListState));
      sessionStorage.setItem('courseId', JSON.stringify(courseId));
    } else {
      const data = {
        useSelectedList: selectedListState,
        ...location.state,
      }
      sessionStorage.setItem('dataCreateCourseIndividual', JSON.stringify(data));
    }
    
    history.goBack();
  };

  window.addEventListener("load", () => {
    sessionStorage.removeItem("useSelectedList");
    sessionStorage.removeItem("courseId");
    sessionStorage.removeItem("dataCreateCourseIndividual");
  });

  useEffect(() => {
    const detailCourse = dataCourse?.result?.data;
    if (detailCourse) {
      const useSelectedListJson = sessionStorage.getItem("useSelectedList");
      const useSelectedList = JSON.parse(useSelectedListJson);

      const selectedUser = useSelectedList ? useSelectedList : JSON.parse(detailCourse?.user_assigned_list || "[]");
      const selectedIds = JSON.stringify(selectedUser.map((user) => user.id));

      setSelectedList(selectedUser);
      setParamsListEmployee((prev) => ({
        ...prev,
        id_selected_list: detailCourse?.is_required_all ? null : selectedIds,
        id_exception_list: detailCourse?.is_required_all ? selectedIds : null,
        no_data: 0,
        isPaging: detailCourse?.is_required_all ? 1 : 0,
      }));
    }
  }, [dataCourse]);

  useEffect(() => {
    if (!courseId && location.state) {
      const dataCourseStore = location.state;
      const useSelectedListJson = location.state.user_selected_list;

      if (!useSelectedListJson) {
        return;
      };

      const useSelectedList = JSON.parse(useSelectedListJson);

      const selectedUser = useSelectedList ?? [];
      const selectedIds = JSON.stringify(selectedUser.map((user) => user.id));

      setSelectedList(selectedUser);
      setParamsListEmployee((prev) => ({
        ...prev,
        id_selected_list: dataCourseStore?.is_required_all ? null : selectedIds,
        id_exception_list: dataCourseStore?.is_required_all ? selectedIds : null,
        no_data: 0,
        isPaging: dataCourseStore?.is_required_all ? 1 : 0,
      }));
    }
  }, []);

  useEffect(() =>{
    // delay set isFirst when loading create. is create dataEmployees = blank
    setTimeout(() =>{
      isFirst.current = false;
    },1000)

    if (!location.state.user_selected_list && isFirst.current) return;
    
    if (dataEmployees) {
      setListEmployee(dataEmployees);
    }
    
  }, [dataEmployees]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 20px 0px">
          <Text fontSize="xl" fontWeight="bold">
            {t("pages.individual.individual_assign_employee")}
          </Text>
        </CardHeader>
        <SearchEmployeeForm
          methods={methods}
          inputList={[
            FIELD_SEARCH_EMPLOYEE_CODE,
            FIELD_SEARCH_EMPLOYEE_NAME,
            FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_TYPE, {
              data: [{ id: "", name: "" }].concat(
                masterData?.employment_types ?? []
              ),
            }),
            FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
            FIELD_SEARCH_EMPLOYEE_ROLE_1,
            FIELD_SEARCH_EMPLOYEE_ROLE_2,
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_JOB, {
              label: "pages.course_form.job_type",
              data: [{ id: "", name: "" }].concat(masterData?.jobs ?? []),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_2, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_2 ?? []
              ),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_3, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_3 ?? []
              ),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_4, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_4 ?? []
              ),
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_DEPARMENT_LAYER_5, {
              data: [{ id: "", name: "" }].concat(
                masterData?.deparment_layer_5 ?? []
              ),
            }),
          ]}
          col={3}
          submitAndClose={submitAndClose}
          handleSubmit={methods.handleSubmit(onSubmit)}
          isModal
          isHideSubmitAndClose={true}
        />
        <SearchEmployeeResult
          isLoading={isLoading}
          dataEmployees={listEmployee}
          paramsListEmployee={paramsListEmployee}
          setParamsListEmployee={setParamsListEmployee}
          pagination={pagination}
          setPagination={setPagination}
          checkAllCondition={checkAllCondition}
          checkSelected={checkSelected}
          handleCheckboxChange={handleCheckboxChange}
          onChangeSelectAll={onChangeSelectAll}
          totalCount={listEmployee?.result?.meta?.total}
          maxHeigh="full"
        />
        <Flex justifyContent="center" pb="20px">
          <Box mr={"10px"}>
            <PrimaryButton
              text={t("pages.course_form.add_member")}
              onClick={submitAndClose}
            />
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

export default IndividualTrainingAssignEmployee;
