import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Flex, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ECheckbox from "components/ECheckbox";
import ETextarea from "components/ETextarea";
import ERadioGroup from "components/ERadioGroup";
import PrimaryButton from "components/Buttons/PrimaryButton";
import SecondaryButton from "components/Buttons/SecondaryButton";
import EDateRangePicker from "components/EDateRangePicker";
import {
  RADIO_DATA_VALID_PERIOD,
  RADIO_DATA_TARGET_RENEWAL_CYCLE,
  PER_PAGE_DEFAULT_EMPLOYEES,
  DOCUMENT_TYPE_INDIVIDUAL_TRAINING,
  COURSE_TYPE_ASSIGN_RANDOM,
  EMPLOYEE_GROUPS,
  FORMAT_DATE,
  PATHNAME,
} from "constants";
import DeleteButton from "components/Buttons/DeleteButton";
import { useModalState } from "hooks/useModalState";
import ModalEmployees from "components/modals/ModalEmployees";
import ESelectWithSearch from "components/ESelectWithSearch";
import { useGetListDocument } from "hooks/useDocument";
import ModalAlert from "components/modals/AlertDialog";
import {
  useUpdateCourse,
  usePushJobManual,
  useDeleteCourse,
  getDetailCourse,
} from "hooks/useCourse";
import GreenButton from "components/Buttons/GreenButton";
import DisableButton from "../../components/Buttons/DisableButton";
import useSearchEmployee from "hooks/useSearchEmployee";
import FullPageLoader from "components/Loading/FullPageLoading";
import ESelect from "components/ESelect";
import { useCreateCourse } from "hooks/useCourse";
import { useListTargetGroup } from "hooks/useTargetGroup";

const IndividualCourseForm = () => {
  const location = useLocation();
  const history = useHistory();
  const { courseId } = useParams();
  const isCreatePage = location.pathname === PATHNAME.CREATE_INDIVIDUAL_COURSE;

  const { modalState, open, close } = useModalState({
    modalEmployees: false,
    modalAlert: false,
  });

  const [paramsDocument, setParamsDocument] = useState({
    page: 1,
  });

  const [fetchedEmployees, setFetchedEmployees] = useState([]);
  const [option, setOption] = useState([]);
  const [tempDocument, setTempDocument] = useState();
  const [isClearDocument, setIsClearDocument] = useState();
  const [detailCourseIndividual, setDetailCourseIndividual] = useState();

  const {data: listTargetGroup} = useListTargetGroup({ type: 2});

  const {
    data: dataCourse,
    loading: isLoadingDetailCourse,
    refetch,
  } = getDetailCourse(courseId, true);
  const {
    data: dataDocuments,
    loading: isLoadingListDocument,
  } = useGetListDocument(paramsDocument);

  const schema = yup.object().shape({
    document_id: yup.object().nullable(),
    // target_group_id: yup.string().required(t("messages.required")),
    validity_from: yup.string().nullable(),
    validity_to: yup
      .string()
      .nullable()
      .test("validity_from", t("messages.date_end"), (value, context) => {
        return !(
          value && new Date(value) < new Date(context.parent.validity_from)
        );
      }),
    group_type: yup.string().required(t("messages.required")),
  });
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      target_group_id: "",
      is_required_all: false,
      effective_time: "1",
      validity_from: "",
      validity_to: "",
      employees: "",
      target_renewal_cycle: "1",
      document_id: null,
      group_type: "",
    },
    resolver: yupResolver(schema),
  });

  const {
    paramsListEmployee,
    onSubmit: onSearchEmployee,
    setParamsListEmployee,
    responseEmployees,
    selectedList,
    setSelectedList,
    exceptionList,
    setExceptionList,
  } = useSearchEmployee(
    {
      is_required_all: watch("is_required_all"),
      is_open: modalState.modalEmployees,
    },
    setValue
  );

  const mutationCreateCourse = useCreateCourse(true);
  const mutationUpdateCourse = useUpdateCourse();
  const mutationDeleteCourse = useDeleteCourse(true);
  const mutationPushJobManual = usePushJobManual({ isPersonal: true });

  const [validateDocument, setValidateDocument] = useState(false);
  const [targetGroups, setTargetGroups] = useState([]);

  //set all fetched employees to fetchedEmployees
  useEffect(() => {
    const newFetchedEmployees = responseEmployees?.result?.data?.filter((i) => {
      return !fetchedEmployees?.some((j) => j.id === i.id);
    });

    if (newFetchedEmployees?.length) {
      setFetchedEmployees([...fetchedEmployees, ...newFetchedEmployees]);
    }
  }, [JSON.stringify(responseEmployees)]);

  const setTextareaValue = () => {
    if (watch("is_required_all")) {
      let textGen = t("pages.course_form.all_people");
      if (Array.isArray(exceptionList) && exceptionList.length > 0) {
        textGen += `     -${exceptionList.map((i) => i.name).join(", ")}`;
      }
      setValue("employees", textGen);
      return;
    }
    if (!selectedList?.length) {
      setValue("employees", "");
      return;
    }
    setValue("employees", selectedList?.map((i) => i.name).join(", "));
  };

  const onPushManual = (id) => {
    if (getValues("document_id")) {
      mutationPushJobManual.mutate({ id: id });
      clearErrors("document_id");
      return;
    }
    setError("document_id", { message: t("messages.required") });
  };

  const onchangeAllCompany = (event) => {
    if (event.target.checked) {
      setValue("employees", t("pages.course_form.all_people"));
    } else {
      setValue("employees", []);
      setSelectedList([]);
    }
  };

  // delete data user_elected when load page
  window.addEventListener("load", () => {
    sessionStorage.removeItem("useSelectedList");
    sessionStorage.removeItem("courseId");
    sessionStorage.removeItem("dataCreateCourseIndividual");
  });

  useEffect(() => {
    if (dataDocuments?.result?.data) {
      setOption(
        [{ label: " コースとの紐付を外す場合に選択", value: "" }].concat(
          dataDocuments?.result?.data
            ?.filter((itm) => {
              return itm.type === DOCUMENT_TYPE_INDIVIDUAL_TRAINING;
            })
            .map((item) => {
              return {
                value: item.id,
                label: `${item.course_code}  ${item.name}`,
              };
            })
        )
      );
    }
  }, [dataDocuments?.result?.data]);

  //set value to form when dataCourse is fetched
  useEffect(() => {
    if (dataCourse?.result?.data && !isCreatePage) {
      const detailCourse = dataCourse.result.data;
      setDetailCourseIndividual(detailCourse);

      const useSelectedListJson = sessionStorage.getItem("useSelectedList");
      const useSelectedList = JSON.parse(useSelectedListJson);

      // check if user_selected is data
      const selectedUser = useSelectedList
        ? useSelectedList
        : JSON.parse(detailCourse?.user_selected_list ?? "[]");

      if (!detailCourse?.is_required_all) {
        setSelectedList(selectedUser);
        setParamsListEmployee({
          ...paramsListEmployee,
          id_selected_list: JSON.stringify(selectedUser.map((item) => item.id)),
          id_exception_list: null,
          no_data: 0,
          isPaging: 0,
        });
      }

      if (detailCourse?.is_required_all) {
        setExceptionList(selectedUser);
        setParamsListEmployee({
          ...paramsListEmployee,
          id_selected_list: null,
          id_exception_list: JSON.stringify(
            selectedUser.map((item) => item.id)
          ),
          no_data: 0,
          isPaging: 1,
        });
      }
      reset({
        target_group_id: detailCourse?.target_group_id,
        is_required_all: !!detailCourse?.is_required_all,
        effective_time: detailCourse?.effective_time?.toString(),
        validity_from: detailCourse?.validity_from
          ? new Date(detailCourse?.validity_from)
          : null,
        validity_to: detailCourse?.validity_to
          ? new Date(detailCourse?.validity_to)
          : null,
        // hide
        user_selected_list: useSelectedListJson
          ? useSelectedListJson
          : detailCourse?.user_selected_list ?? [],
        employees: useSelectedList
          ? useSelectedList
          : JSON.parse(detailCourse?.user_selected_list ?? "[]")
              .map((i) => i.name)
              .join(", "),
        // user_assigned_list: detailCourse?.user_assigned_list ?? [],
        // employees: JSON.parse(detailCourse?.user_assigned_list ?? '[]')
        //   .map((i) => i.name)
        //   .join(", "),
        target_renewal_cycle: detailCourse?.target_renewal_cycle?.toString(),
        document_id: option?.find((i) => {
          if (i) return i.value === detailCourse.document_id;
        }),
        group_type: COURSE_TYPE_ASSIGN_RANDOM[detailCourse?.type],
      });
      setTempDocument(
        option?.find((i) => {
          if (i) return i.value === detailCourse.document_id;
        })
      );
    }

    if (isCreatePage) {
      // setDetailCourseIndividual(detailCourse);

      const dataCreateCourseIndividualJson = sessionStorage.getItem("dataCreateCourseIndividual");

      if (!dataCreateCourseIndividualJson) return;

      const dataCreateCourseIndividual = JSON.parse(dataCreateCourseIndividualJson);

      // check if user_selected is data
      const selectedUser = dataCreateCourseIndividual.useSelectedList;

      if (!dataCreateCourseIndividual?.is_required_all) {
        setSelectedList(selectedUser);
        setParamsListEmployee({
          ...paramsListEmployee,
          id_selected_list: JSON.stringify(selectedUser.map((item) => item.id)),
          id_exception_list: null,
          no_data: 0,
          isPaging: 0,
        });
      }

      if (dataCreateCourseIndividual?.is_required_all) {
        setExceptionList(selectedUser);
        setParamsListEmployee({
          ...paramsListEmployee,
          id_selected_list: null,
          id_exception_list: JSON.stringify(
            selectedUser.map((item) => item.id)
          ),
          no_data: 0,
          isPaging: 1,
        });
      }
      reset({
        target_group_id: dataCreateCourseIndividual?.target_group_id,
        is_required_all: !!dataCreateCourseIndividual?.is_required_all,
        effective_time: dataCreateCourseIndividual?.effective_time?.toString(),
        validity_from: dataCreateCourseIndividual?.validity_from
          ? new Date(dataCreateCourseIndividual?.validity_from)
          : null,
        validity_to: dataCreateCourseIndividual?.validity_to
          ? new Date(dataCreateCourseIndividual?.validity_to)
          : null,
        // hide
        user_selected_list: JSON.stringify(selectedUser),
        employees: selectedUser
              .map((i) => i.name)
              .join(", "),
        // user_assigned_list: dataCreateCourseIndividual?.user_assigned_list ?? [],
        // employees: JSON.parse(dataCreateCourseIndividual?.user_assigned_list ?? '[]')
        //   .map((i) => i.name)
        //   .join(", "),
        target_renewal_cycle: dataCreateCourseIndividual?.target_renewal_cycle?.toString(),
        document_id: dataCreateCourseIndividual.document_id,
        group_type: dataCreateCourseIndividual?.group_type,
      });
      setTempDocument(
        option?.find((i) => {
          if (i) return i.value === dataCreateCourseIndividual.document_id;
        })
      );
    }
  }, [dataCourse?.result?.data, option]);

  const onSubmit = async (data) => {
    if (!data.document_id || !data.document_id.value) {
      setValidateDocument(true);
      return;
    };

    if (!data.is_required_all) {
      data.employees = selectedList.map((i) => i.id);
    }
    const payload = {
      target_group_id: data.target_group_id,
      is_required_all: +data.is_required_all,
      effective_time: data.effective_time,
      target_renewal_cycle: data.target_renewal_cycle,
      document_id:
        data.document_id && data.document_id.value
          ? data.document_id.value
          : null,
      sub_list: [],
      group_type: data.group_type,
    };

    if (data.effective_time == 2) {
      payload.validity_from = moment(data.validity_from).isValid()
        ? moment(data.validity_from).format("YYYY-MM-DD")
        : "";
      payload.validity_to = moment(data.validity_to).isValid()
        ? moment(data.validity_to).format("YYYY-MM-DD")
        : "";
    }

    if (!data.is_required_all) {
      payload.user_selected_list = selectedList.map((i) => i.id);
      payload.sub_list = [];
    } else {
      payload.sub_list = exceptionList.map((i) => i.id);
      payload.user_selected_list = [];
    }

    if (isCreatePage) {
      mutationCreateCourse.mutate(payload);
      return;
    }

    mutationUpdateCourse.mutate({ ...payload, id: courseId });
  };

  const handleSelectEmployee = () => {
    if (courseId && !isCreatePage) {
      history.push(
        `/admin/individual-training/assign-training`,
        {...getValues(), courseId}
      )
      sessionStorage.setItem('useSelectedList', JSON.stringify(selectedList));
      return;
    }

    history.push(
      `/admin/individual-training/assign-training`,
      {...getValues(), courseId}
    )
  };

  const handelCourseChange = (data) => {
    setValue("document_id", data);
    setValidateDocument(false);
    if (data?.value) {
      setIsClearDocument(false);
    }
  };

  useEffect(() => {
    if (isCreatePage) {
      setSelectedList([]);
      setExceptionList([]);
    }

    setParamsListEmployee({
      page: 1,
      limit: PER_PAGE_DEFAULT_EMPLOYEES,
      sortType: null,
      no_data: watch("is_required_all") ? 0 : 1,
      isPaging: watch("is_required_all") ? 1 : 0,
    });
  }, [watch("is_required_all")]);

  useEffect(() => {
    setTextareaValue();
  }, [selectedList, exceptionList]);

  // auto refresh detail data. if campus_status === 2
  useEffect(() => {
    const interval = setInterval(async () => {
      if (detailCourseIndividual?.campus_status === 2) {
        await refetch();
      }
    }, 30000);
  }, [detailCourseIndividual]);

  useEffect(() => {
    if (listTargetGroup?.result?.data) {
      const targetGroups = [...listTargetGroup.result.data].reverse();
      setTargetGroups([...targetGroups]);
    }
  }, [listTargetGroup?.result?.data]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      {(isLoadingDetailCourse || isLoadingListDocument) && <FullPageLoader />}
      <Card overflowX={"hidden"} pb="0px">
        <CardHeader p="6px 0px 20px 0px">
          <Flex justifyContent={"space-between"}>
            <Text fontSize="xl" fontWeight="bold">
              {t("pages.individual.individual_edit_title")}
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap={"10px"} align={"center"} mb={"10px"}>
              <Box w={"15%"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.label.position_group")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <ESelect
                  name="target_group_id"
                  placeholder={t("common.option.placeholder")}
                  control={control}
                  dataSelect={targetGroups.map((option) => {
                    return { id: option.id, name: t(option.name) };
                  })}
                />
              </Box>
            </Flex>

            <Flex gap={"10px"} mb={"10px"}>
              <Box w={"15%"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.label.document")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <ESelectWithSearch
                  name="document_id"
                  control={control}
                  options={option}
                  placeholder={t("pages.course_form.document_placeholder")}
                  onChange={handelCourseChange}
                />
                {validateDocument && <Text fontSize={14} marginTop={1} color={'red.500'}>{t("messages.required")}</Text>}
              </Box>
            </Flex>

            <Flex gap={"10px"} mb={"10px"} mt={"20px"}>
              <Box w={"15%"} mt={"8px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.label.valid_time")}
                </Text>
              </Box>
              <Flex width={"50%"}>
                <Box w={"150px"} mt={"8px"}>
                  <ERadioGroup
                    name="effective_time"
                    control={control}
                    radioData={RADIO_DATA_VALID_PERIOD}
                  />
                </Box>
                <Box ml={"10px"} w={"40%"}>
                  <EDateRangePicker
                    disabled={watch("effective_time") !== "2"}
                    setValue={setValue}
                    control={control}
                    name="validity_from"
                    getValues={getValues}
                    reset={reset}
                    placeholder="YYYY/MM/DD"
                    watch={watch}
                    dateFormat={FORMAT_DATE}
                  />
                </Box>
                <Box ml={"10px"} mt={"8px"}>
                  ~
                </Box>
                <Box ml={"10px"} w={"40%"}>
                  <EDateRangePicker
                    disabled={watch("effective_time") !== "2"}
                    setValue={setValue}
                    name="validity_to"
                    control={control}
                    getValues={getValues}
                    reset={reset}
                    placeholder="YYYY/MM/DD"
                    watch={watch}
                    dateFormat={FORMAT_DATE}
                  />
                </Box>
              </Flex>
            </Flex>

            <Flex gap={"10px"} align={"center"} mb={"10px"}>
              <Box w={"15%"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.label.required_all")}
                </Text>
              </Box>
              <Box width={"50px"} mt={"10px"}>
                <ECheckbox
                  disabled
                  name="is_required_all"
                  control={control}
                  onChange={onchangeAllCompany}
                />
              </Box>
              <Box w={"100px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.label.renew")}
                </Text>
              </Box>
              <Box width={"30%"}>
                <ERadioGroup
                  name="target_renewal_cycle"
                  placeholder=""
                  control={control}
                  radioData={RADIO_DATA_TARGET_RENEWAL_CYCLE}
                />
              </Box>
              {/* </Flex> */}
            </Flex>

            <Flex gap={"10px"} mb={"10px"}>
              <Box w={"15%"} mt={"8px"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.label.employees")}
                </Text>
              </Box>
              <Box width={"50%"}>
                <ETextarea
                  name="employees"
                  placeholder=""
                  control={control}
                  readonly
                />
              </Box>
              <Box mt={"8px"}>
                <PrimaryButton
                  text={t("pages.individual.member_confirmation")}
                  onClick={handleSelectEmployee}
                />
              </Box>
            </Flex>

            <Flex justifyContent={"end"} gap={"20px"} pb={"10px"}>
              {isCreatePage ? (
                <PrimaryButton
                  text={t("pages.course_form.register")}
                  type="submit"
                  isLoading={mutationCreateCourse.isLoading}
                />
              ) : (
                <Flex gap={"20px"}>
                  {dataCourse?.result?.data?.campus_status == 2 ? (
                    <DisableButton
                      text={t("pages.course_form.save_changes")}
                      type="submit"
                      isLoading={mutationUpdateCourse.isLoading}
                    />
                  ) : (
                    <PrimaryButton
                      text={t("pages.course_form.save_changes")}
                      type="submit"
                      isLoading={mutationUpdateCourse.isLoading}
                    />
                  )}
                  <DeleteButton
                    text={t("pages.course_form.un_assign_course")}
                    onClick={() => open("modalAlert")}
                  />
                </Flex>
              )}
              <SecondaryButton
                text={t("pages.course_form.cancel")}
                onClick={() => history.go(-1)}
              />
            </Flex>
          </form>
          {!isCreatePage && <Flex justifyContent={"center"} pb={"20px"}>
            {dataCourse?.result?.data?.campus_status == 2 ? (
              <DisableButton
                text={t("pages.course_form.pushing")}
                isLoading={"true"}
              />
            ) : (
              <GreenButton
                text={t("pages.course_form.cloud_campus_status_1")}
                onClick={() => onPushManual(courseId)}
                disabled={dataCourse?.result?.data?.campus_status === 4}
              />
            )}
          </Flex>}
        </CardBody>
      </Card>
      <ModalAlert
        isOpen={modalState.modalAlert}
        onClose={() => close("modalAlert")}
        title={t("pages.course_form.delete_course")}
        content={t("pages.course_form.confirm_delete_course")}
        textBtnLeft={t("pages.course_form.cancel")}
        textBtnRight={t("pages.course_form.delete")}
        onClickBtnLeft={() => close("modalAlert")}
        onClickBtnRight={() => mutationDeleteCourse.mutate(courseId)}
      />
    </Flex>
  );
};

export default IndividualCourseForm;
