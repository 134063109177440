import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next";
import { useGetListCourse } from "hooks/useCourse";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  useColorModeValue,
  Text,
} from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Pagination from "components/Pagination/Pagination";
import EInput from "components/EInput";
import {
  DOCUMENT_TYPE_INDIVIDUAL_TRAINING,
  COURSE_TYPE_ASSIGN_RANDOM,
  EMPLOYEE_TARGET_GROUP_BUSSINESS,
  EMPLOYEE_TARGET_GROUP_OTHER_BUSSINESS,
  EMPLOYEE_TARGET_GROUP_REGISTERED,
} from "constants";

const IndividualTrainings = () => {
  const [searchName, setSearchName] = useState("");
  const [targetGroup, setTargetGroup] = useState(null);
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    sort: "",
    name: "",
    target_group_id: null,
    sortType: null,
    target_group_id: [
      EMPLOYEE_TARGET_GROUP_BUSSINESS,
      EMPLOYEE_TARGET_GROUP_OTHER_BUSSINESS,
      EMPLOYEE_TARGET_GROUP_REGISTERED,
    ],
    document_type: DOCUMENT_TYPE_INDIVIDUAL_TRAINING,
  });

  const history = useHistory();
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { data, isLoading, error } = useGetListCourse(params);
  const tableData = data?.result?.data;
  const total = data?.result?.meta?.total;
  const schema = yup.object().shape({
    name: yup.string().max(255, t("messages.common_e002")),
  });
  const { handleSubmit, control } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const changeSort = (sortBy) => {
    setParams((prevState) => {
      const tempData = { ...prevState };
      tempData.sort = sortBy;
      if (tempData.sortType) {
        tempData.sortType === -1
          ? (tempData.sortType = 1)
          : (tempData.sortType = -1);
      } else {
        tempData.sortType = 1;
      }

      return tempData;
    });
  };

  const search = (data) => {
    setSearchName(data.name);
    setParams((prevState) => {
      const tempData = { ...prevState };
      tempData.page = 1;
      tempData.name = data.name;
      tempData.target_group_id = targetGroup;
      return tempData;
    });
  };

  useEffect(() => {
    search({ name: searchName });
  }, [targetGroup]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 10px 0px">
          <Box justifyContent={"space-between"}>
            <Text fontSize="xl" fontWeight="bold">
              {t("pages.individual.list.title")}
            </Text>
          </Box>
          <Flex align="end">
            <Box w="50%" minWidth="645px">
              <form onSubmit={handleSubmit(search)}>
                <Flex mt="20px">
                  <EInput
                    control={control}
                    controlStyle={{ w: "unset", d: "" }}
                    label={t("pages.courses.search_form.search_label")}
                    placeholder={t("pages.courses.search_form.search_label")}
                    name="name"
                    labelStyle={{
                      w: "150px",
                      mt: "7px",
                      d: "inline-flex",
                      fontSize: "14px",
                    }}
                    style={{ w: "330px", d: "inline-flex", fontSize: "14px" }}
                    errorStyle={{ ml: "32%" }}
                  />
                  <Button
                    type="submit"
                    onClick={handleSubmit(search)}
                    ml="20px"
                    background="#007bff"
                    color="white"
                    fontSize="14px"
                    w="80px"
                    mt={"5px"}
                    _hover={{ background: "#007bff99" }}
                    size={"sm"}
                  >
                    {t("pages.courses.search_form.search_button")}
                  </Button>
                </Flex>
              </form>
            </Box>
            <Spacer />
            <Box w="50%" flex="1" textAlign="right" pr="6%">
              <Button
                onClick={() => history.goBack()}
                color="white"
                background="#007bff"
                fontSize="14px"
                _hover={{ background: "#007bff99" }}
                size={"sm"}
              >
                {t("pages.individual.list.btn_create")}
              </Button>
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="simple" size={"xsm"} w="100%">
              <Thead>
                <Tr>
                  <Th
                    borderColor={borderColor}
                    color="black.600"
                    width="4%"
                    fontSize="14px"
                  ></Th>
                  <Th
                    borderColor={borderColor}
                    color="black.600"
                    fontSize="14px"
                  >
                    {t("pages.course_form.label.position_group")}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="black.600"
                    fontSize="14px"
                  >
                    {t("pages.courses.table.course_name_ver")}
                    {!params.sortType ||
                    (params.sort === "course_name" &&
                      params.sortType === -1) ? (
                      <ChevronDownIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort("course_name")}
                      />
                    ) : (
                      <ChevronUpIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort("course_name")}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="black.600"
                    fontSize="14px"
                    w="15%"
                  >
                    {t("pages.courses.table.target_person_designation")}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="black.600"
                    textAlign="center"
                    fontSize="14px"
                    w="20%"
                  >
                    {t("pages.courses.table.detail")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData &&
                  tableData.map((item, index) => (
                    <Tr key={index}>
                      <Td
                        borderColor={borderColor}
                        textAlign="center"
                        whiteSpace="pre-line"
                        wordBreak="break-all"
                      >
                        <Text noOfLines={1} fontSize="14px">
                          {index + 1 + params.limit * (params.page - 1)}
                        </Text>
                      </Td>
                      <Td
                        borderColor={borderColor}
                        whiteSpace="pre-line"
                        wordBreak="break-all"
                      >
                        <Text noOfLines={1} fontSize="14px">
                          {item?.target_group?.name}
                        </Text>
                      </Td>
                      <Td
                        borderColor={borderColor}
                        whiteSpace="pre-line"
                        wordBreak="break-all"
                      >
                        <Text noOfLines={1} fontSize="14px">
                          {item.document?.name}
                        </Text>
                      </Td>
                      <Td
                        borderColor={borderColor}
                        textAlign="left"
                        fontSize="14px"
                      >
                        {item.target_renewal_cycle === 1
                          ? t("pages.courses.table.manual")
                          : t("pages.courses.table.auto")}
                      </Td>
                      <Td borderColor={borderColor} textAlign="center">
                        <Button
                          onClick={() =>
                            history.push(`/admin/individual-training/courses/edit/${item.id}`)
                          }
                          color="white"
                          background="#007bff"
                          fontSize="14px"
                          _hover={{ background: "#007bff99" }}
                          size={"xs"}
                        >
                          {t("pages.courses.table.detail_button")}
                        </Button>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {Math.ceil(total / params.limit) > 1 && (
              <Pagination
                total={total}
                perPage={params.limit}
                currentPage={params.page}
                setParams={setParams}
              />
            )}
          </TableContainer>
          {isLoading && (
            <Box textAlign="center" mt="40px">
              <Spinner></Spinner>
            </Box>
          )}
          {tableData && tableData.length === 0 && (
            <Box textAlign="center" pt="20px" color="gray.400" fontSize="14px">
              {t("messages.common_s003")}
            </Box>
          )}
          {error && (
            <Box textAlign="center" pt="20px" color="gray.400" fontSize="14px">
              {t("messages.common_s005")}
            </Box>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
};

export default IndividualTrainings;
