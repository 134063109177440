import {
  DOCUMENT_TYPE_OVERALL_TRAINING,
  DOCUMENT_TYPE_INDIVIDUAL_TRAINING,
} from "constants";
import { t } from "i18next";

export const translateNameOption = (options) =>
  options.map((option) => ({
    ...option,
    name: t(option.name),
  }));

export const documentTypeName = (type) => {
  if (type === DOCUMENT_TYPE_OVERALL_TRAINING)
    return t("pages.document.overall_training");
  if (type === DOCUMENT_TYPE_INDIVIDUAL_TRAINING)
    return t("pages.document.individual_training");

  return "";
};

export const customSearchEmployeeInput = (elem, customValues) => {
  const inputElem = { ...elem };

  for (const key in customValues) {
    if (customValues.hasOwnProperty(key)) {
      inputElem[key] = customValues[key];
    }
  }

  return inputElem;
};
